:root {
  --color-theme: #000d14;
  --header-color: #B2A092;
  --text-dark: #000000;
  --text-light: #ffffff;
}

@font-face {
  font-family: Gotham;
  src: url(/public/fonts/Gotham/Gotham-Book.otf);
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: Gotham, sans-serif;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-theme);
  color: var(--text-light);
}

.container {
  margin: 0 auto;
  max-width: 800px;
  width: 80%;
  text-align: center;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.8));
}

h1 {
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.8));
  margin-bottom: 0px;
  margin-top: 2vh;
  font-size: 4vw;
}

.landing {
  padding-bottom: 2vh;
  filter: drop-shadow(0px 0px 10vw rgb(53, 81, 117))  drop-shadow(0px 0px 3vh rgb(97, 97, 97));
}

@media only screen and (min-width: 768px) {
  h1{
    font-size: 2em;
  }
}
